<template>
  <div>
    <h2><slot>Партнёры</slot></h2>
    <section id="partners">
      <a class="partners_item" v-for="(item, index) in sortedList" :key="index" :href="item.url">
<!--        <img :src="`${require('/assets/temp/partners/'+ item.img)}`" :alt="item.title">-->
        <img :src="`${$store.getters.getUrlApi}${item.img}`" :alt="item.title">
      </a>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ForumPartners',
  props: {
    partnersList: {
      type: Array,
      required: true,
      default: function () {
        return {
          title: null,
          img: null,
          url: '/'
        }
      }
    }
  },
  computed: {
    sortedList () {
      return [...this.partnersList]?.sort((a, b) => a.sort - b.sort)
    }
  }
}
</script>

<style lang="scss" scoped>
  h2 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.33;
    color: #1F3245;
    margin-bottom: 1.25rem;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1.75rem;

    > a {
      margin: 1rem;
      width: 10rem;
      height: 7.5rem;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      border: 1px solid #E5E8EE;
      box-sizing: border-box;
      border-radius: 8px;
      box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
      margin-bottom: 1.25rem;
      > img {
        object-fit:contain;
        max-height: 100%;
      }
    }
    a:first-child {
      margin-left: 0;
    }

    a:last-child {
      margin-right: 0;
    }
    a:nth-child(6n)  {
      margin-right: 0;
    }
    a:nth-child(7n)  {
      margin-left: 0;
    }
  }
  @media screen and (max-width: 768px) {
    section {
      justify-content: space-evenly;
    }

  }

  @media screen and (max-width: 420px) {

  }

</style>
